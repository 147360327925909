<template>
  <div class="container-div">
    <div class="table-div">
      <div class="input-div">
        
        <Input style="width:200px" v-model="searchName" placeholder="请输入姓名、手机号查询" />
        <Button style="margin-left:10px" type="primary" @click="search">查询</Button>
        <Button style="margin-left:10px" type="info" @click="reset">重置</Button>
      </div>
      <!-- <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        height="660"
        :curPage="curPage"
        :total="total"
        :page-size="pageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        @hadlePageSize="hadlePageSize"
        @tableSelect="tableSelect"
      ></LiefengTable> -->
      <Table height="660" :loading="loading" @on-select="tableSelect"  @on-select-all="onSelectAll"
           @on-select-all-cancel="onSelectAllCancel" @on-select-cancel="onSelectCancel" :columns="talbeColumns" :data="tableData"  stripe border ></Table>
        <!-- style="float: right; margin: 10px" -->
     <Page
       style="float:right;margin:10px;"
        @on-change="morePageFn"
        :current="curPage"
        @on-page-size-change="PageSizeChange"
        :page-size="pageSize"
        :total="total"
        :page-size-opts="[20, 50, 100, 200]"
        size="small"
        show-total
        show-elevator
        show-sizer
         />

    </div>
    <div class="btn-div">
      <Button type="error" style="margin-bottom:5px" @click="deteleList">删除</Button>
      <Button type="success" style="margin-bottom:5px" @click="clearBotn">清空</Button>
    </div>
    <div class="select-div">
      <div class="select-header">
        <div class="select-title-none"></div>
        <div class="select-title-left">姓名</div>
        <div class="select-title-right">手机号</div>
      </div>
      <div class="select-container" v-if="selectList && selectList.length > 0">
        <div
          :class="index%2 ==0 ?'background-div':'background-div-two'"
          v-for="(item,index) in selectList "
          :key="index"
        >
          <div class="item-none">
            <Checkbox v-model="item.status"></Checkbox>
          </div>
          <div class="item-left">{{item.name}}</div>
          <div class="item-right">{{item.mobile}}</div>
        </div>
      </div>
      <div class="none-table" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
export default {
  props: {
    gridId: {
      type: String,
      default: () => {
        return "";
      }
    },
    selectOrg: {
      type: String,
      default: () => {
        return "";
      }
    },
    showNum:{
      type:Number,
      default:()=>{
          return 0
      }
    }
    // selectList: {
    //   type: Array,
    //   default: () => {
    //     return [{}];
    //   }
    // }
  },
  data() {
    //这里存放数据
    return {
      tableData: [],
      loading: false,
      curPage: 1,
      total: 0,
      pageSize: 20,
      page: 1,
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "姓名",
          minWidth: 130,
          key: "name",
          align: "center"
        },
        {
          title: "手机号",
          minWidth: 130,
          key: "mobile",
          align: "center"
        }
      ],
      modeltrue: true,
      selectList: [],
      lastList: [],
      searchName: ""
    };
  },
  //方法集合
  methods: {
    //   方法部分
    reset() {
      this.searchName = "";
      this.search();
    },
    search() {
      let params = {
        orgCode: this.selectOrg,
        pageSize: 20,
        page: 1
      };
      this.getList(params);
    },
    // 点击清空按钮
    clearBotn() {
      this.selectList = [];
      this.$emit("selectList", this.selectList);
      let data = {
        orgCode: this.selectOrg,
        pageSize: this.pageSize,
        page: this.curPage
      };
      this.getList(data);
    },
    // 改变页数的方法
    // hadlePageSize(data) {
    //   let params = {
    //     orgCode: this.selectOrg,
    //     pageSize: data.pageSize,
    //     page: data.page
    //   };
    //   this.getList(params);
    // },
    PageSizeChange(val){
      let params = {
        orgCode: this.selectOrg,
        pageSize: val,
        page:this.curPage
      };
      this.getList(params);
    },
    morePageFn(val){
       let params = {
        orgCode: this.selectOrg,
        page: val,
        pageSize:this.pageSize
      };
      this.getList(params);
    },
    unique(arr) {
      const res = new Map();
      return arr.filter(
        arr => !res.has(arr.custGlobalId) && res.set(arr.custGlobalId, 1)
      );
    },
    qufei(array1,array2){
        let result = []
        for(var i = 0; i < array2.length; i++){
            var obj = array2[i];
            var num = obj.custGlobalId;
            var isExist = false;
            for(var j = 0; j < array1.length; j++){
                var aj = array1[j];
                var n = aj.custGlobalId;
                if(n == num){
                    isExist = true;
                    break;
                }
            }
            if(!isExist){
                result.push(obj);
            }
        }
        return result
    },
    // 求补集
    getOtherList(array1, array2) {
      var result = [];
      for (var i = 0; i < array2.length; i++) {
        var obj = array2[i];
        var num = obj.custGlobalId;
        var isExist = false;
        for (var j = 0; j < array1.length; j++) {
          var aj = array1[j];
          var n = aj.custGlobalId;
          if (n == num) {
            isExist = true;
            break;
          }
        }
        if (!isExist) {
          result.push(obj);
        }
      }
      return result;
    },
    // 取消当前行
     onSelectCancel(selection, row) {
      this.selectList.map((item,index)=>{
        if(item.custGlobalId == row.custGlobalId){
          this.selectList.splice(index,1)
        }
      })
      this.$emit("selectList", this.selectList);
      console.log('删除当前行的',this.selectList);
    },
    // 取消全选按钮
    onSelectAllCancel(val){
      this.selectList = this.qufei(this.tableData,this.selectList)
      this.$emit("selectList", this.selectList);
      console.log('取消全选后的数组',this.selectList);
    },
    // 全选
    onSelectAll(val){
      val.map(item =>{
        this.selectList.push(item)
      })
      this.selectList = this.$core.onlyList(this.selectList, "custGlobalId");
      this.$emit("selectList", this.selectList);
      console.log('全选的状态',this.selectList);

    },
    // 点击选择框回调事件
    tableSelect(val) {
      if(val && val.length != 0){
        val.map(item =>{
          this.selectList.push(item)
        })
      }
      this.selectList = this.unique(this.selectList);
      this.$emit("selectList", this.selectList);

      console.log('去重后的数组');
      // // 刚进页面的时候点击全选按钮
      // if (val.length == 0) {
      //   this.selectList = this.getOtherList(this.tableData, this.selectList);
      //   this.$emit("selectList", this.selectList);
      // } else {
      //   if (val.length == this.tableData.length) {
      //     val.map(item => {
      //       this.selectList.push(item);
      //     });
      //     this.$emit("selectList", this.selectList);
      //   }
      //   if (this.lastList.length == 0 && val.length != 0) {

      //     if (val.length == 1) {
      //       this.selectList.push(val[0]);
      //       this.$emit("selectList", this.selectList);
      //     }
      //     if (val.length != this.tableData.length) {
      //       let list9 = this.getOtherList(val, this.tableData);
      //       this.selectList.map((item, index) => {
      //         if (item.custGlobalId == list9[0].custGlobalId) {
      //           this.selectList.splice(index, 1);
      //         }
      //       });

      //       this.$emit("selectList", this.selectList);
      //     } else {
      //       val.map(item => {
      //         this.selectList.push(item);
      //       });
      //       this.$emit("selectList", this.selectList);
      //     }
      //   } else {
      //     if (val.length > this.lastList.length) {

      //       let list1 = val.concat(this.lastList);
      //       let list2 = this.unique(list1);
      //       let list3 = list2.concat(this.selectList);
      //       this.selectList = this.unique(list3);
      //       this.$emit("selectList", this.selectList);
      //     } else {
      //       if (this.selectList.length == 0) {

      //         val.map(item => {
      //           this.selectList.push(item);
      //         });
      //         this.$emit("selectList", this.selectList);
      //       } else {
      //         if (val.length == 1) {
      //           this.selectList.push(val[0]);
      //           this.$emit("selectList", this.selectList);
      //         } else {
      //           if (val.length < this.lastList.length) {
      //             let list7 = this.getOtherList(val, this.tableData);
      //             let list8 = this.getOtherList(list7, this.selectList);
      //             this.selectList = list8;
      //             this.$emit("selectList", this.selectList);
      //           } else {
      //             let list10 = this.getOtherList(val, this.tableData);
      //             if (list10.length == 0) {
      //               val.map(item => {
      //                 this.selectList.push(item);
      //               });
      //             }
      //             this.$emit("selectList", this.selectList);


      //           }
      //         }
      //       }
      //     }
      //   }
      //   this.lastList = val;
      // }

      // this.selectList = this.unique(this.selectList);
      console.log('val数组',val);
      console.log('进来就跑的数组',this.selectList);
    },
    // 点击删除按钮的事件
    deteleList() {
      console.log('点击了删除的按钮',this.selectList,this.selectList.length);
      if (this.selectList.length == 0) {
        this.$Message.warning({
          content: "请选择删除的网格员",
          background: true
        });
        return;
      } else {
        let List = [];
        this.selectList.map(item => {
          if (item.status === true) {
            List.push(item);
          }
        });
        console.log("全部", this.selectList);
        console.log("勾选的", List);
        var result = [];
        for (var i = 0; i < this.selectList.length; i++) {
          var obj = this.selectList[i];
          var num = obj.custGlobalId;
          var flag = false;
          for (var j = 0; j < List.length; j++) {
            var aj = List[j];
            var n = aj.custGlobalId;
            if (n == num) {
              flag = true;
              break;
            }
          }
          if (!flag) {
            result.push(obj);
          }
        }
        this.selectList = result;
        this.selectList.map(item => {
          item.status = false;
        });
        // let formatterList = this.selectList.concat(List);
        // this.selectList = this.unique(formatterList);

        this.$emit("selectList", this.selectList);
        let params = {
          orgCode: this.selectOrg,
          pageSize: this.pageSize,
          page: this.curPage
        };
        this.getList(params);
      }
    },

    // 接口部分
    getList(data) {
      // this.loading = true;
      let params = {
        name:this.searchName,
        staffOrgCode:parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        page: data.page,
        pageSize: data.pageSize,
        orgCode:this.selectOrg
      };
      this.$get("/syaa/pc/sy/user/communityStructure/selectUserStructurePage",params)
      // this.$get("/gx/pc/staff/selectPageByOrgCode", params)
      .then(res => {
        this.tableData = [];
        // this.loading = false;
        let data = res.dataList;
        if (this.selectList && this.selectList.length != 0) {
          // 将选中的数组进行存储，并且转换为数组，在转换为以逗号分隔的字符串
          let List = [];
          let listString = "";
          this.selectList.map(item => {
            List.push(item.custGlobalId);
          });
          listString = List.join(",");
          // 循环遍历数组，匹配字符串，匹配成功就将复选框打勾，没有则复选框取消勾选
          data.map(item => {
            if (listString.indexOf(item.custGlobalId) > -1) {
              item._checked = true;
            } else {
              item._checked = false;
            }
          });
        } else {
          data.map(item => {
            item._checked = false;
          });
        }

        this.tableData = data;
        this.total = res.maxCount;
        this.curPage = res.currentPage;
      });
    },
    getSelect(data) {
      this.$post("/gx/pc/grid/queryStaffByGridId", {
        gridId: data
      }).then(res => {
        if (res.code == 200) {
          res.dataList.map(item => {
            item._checked = false;
            item.name = item.userName
          });
          this.selectList = res.dataList;
          this.$emit("selectList", this.selectList);
          // let params = {
          //   orgCode: this.selectOrg,
          //   pageSize: 20,
          //   page: 1
          // };
          // this.getList(params);
        }
      });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  components: {
    LiefengTable
  },
  watch: {
    showNum(){
      this.selectList = [];
          this.getSelect(this.gridId);
          this.lastList = [];
          this.selectList = [];
          let data = {
            orgCode: this.selectOrg,
            page: 1,
            pageSize: 20
          };
          this.getList(data);
    },
    // gridId: {
    //   handler(val, newVal) {
    //     if (val) {
    //       this.selectList = [];
    //       this.getSelect(val);
    //       this.lastList = [];
    //       this.selectList = [];
    //       let data = {
    //         orgCode: val,
    //         page: 1,
    //         pageSize: 20
    //       };
    //       this.getList(data);
    //     }
    //   }
    // },
    // selectOrg: {
    //   handler(val, newVal) {
    //     if (val) {
    //       console.log('组件重进');
          
    //     }
    //   }
    // }
  }
};
</script>
<style lang='less' scoped>
//编写css
.container-div {
  display: flex;
  justify-content: center;
  width: 100%;
  //   height: 750px;
  .table-div {
    width: 45%;
    .input-div {
      margin: 10px 0;
    }
  }
  .btn-div {
    width: 5%;
    text-align: center;
    padding-top: 250px;
  }
  .select-div {
    border: 1px solid #e8eaec;
    width: 45%;
    position: relative;
    margin-top: 51px;
    margin-bottom: 44px;
    overflow-x: hidden;
    .select-header {
      background-color: #f8f8f9;
      top: 0;
      left: 0;
      position: absolute;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #515a6e;
      width: 100%;
      // background-color: #fff;
      z-index: 999;
      display: flex;
      justify-content: flex-start;
      text-align: center;
      border-top: 1px solid #e8eaec;
      border-left: 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
      .select-title-none {
        width: 10%;
        height: 100%;
      }
      .select-title-left {
        border-left: 1px solid #e8eaec;
        width: 45%;
        height: 100%;
      }
      .select-title-right {
        border-left: 1px solid #e8eaec;
        width: 45%;
        height: 100%;
      }
    }
    .select-container {
      z-index: 1;
      padding-top: 40px;
      height: 660px;
      overflow-y: scroll;
      .background-div {
        width: 100%;
        border-top: 1px solid #e8eaec;
        height: 48px;
        line-height: 48px;
        display: flex;
        justify-content: flex-start;
        text-align: center;
        .item-none {
          width: 10%;
        }
        .item-left {
          border-left: 1px solid #e8eaec;
          margin-left: 3px;
          width: 45%;
        }
        .item-right {
          margin-left: 6px;
          border-left: 1px solid #e8eaec;
          width: 45%;
        }
      }
      .background-div-two {
        background-color: #f8f8f9;
        width: 100%;
        border-top: 1px solid #e8eaec;
        height: 48px;
        line-height: 48px;
        display: flex;
        justify-content: flex-start;
        text-align: center;
        .item-none {
          width: 10%;
        }
        .item-left {
          border-left: 1px solid #e8eaec;
          margin-left: 3px;
          width: 45%;
        }
        .item-right {
          margin-left: 6px;
          border-left: 1px solid #e8eaec;
          width: 45%;
        }
      }
    }
    .none-table {
      text-align: center;
      color: #cccccc;
      margin-top: 60px;
    }
  }
}
.background-div:last-child {
  border-bottom: 1px solid #e8eaec;
}

/deep/#modal_contentarea {
  padding: 0px;
}
</style>